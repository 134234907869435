import { ComboBox, DefaultButton, DialogFooter, IComboBoxOption, Icon, IconButton, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, Pivot, PivotItem, PrimaryButton, Separator, Spinner, SpinnerSize, Stack, StackItem, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, values } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik, getIn } from "formik";
import { FormEventHandler, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, getManager, getOrganization, getRoles, getUserTypes, OrganizationState, reset, resetRoles, resetUserTypes, setCurrentOrganization, setIsFilteredRolesSet, setSelectedRole, setSelectedUserType, setStage1, setStage2, setStage3, update, updateAccountSecurity, updateUsername } from "./UsersFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { GetUserRequest, UpdateAccountSecurityRequest, UpdateAccountSecurityResponse } from "../../../../repository/UserManagement/Managers/user_pb";

import { Address, ExtraField } from "../../../../repository/UserManagement/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { GetUserTypesRequest } from "../../../../repository/UserManagement/Managers/usertype_pb";
import { GetRolesRequest } from "../../../../repository/UserManagement/Managers/role_pb";
import { setIsFilteredUserTypesSet } from "../../../pages/Management/Users/UsersPageSlice";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { GetOrganizationRequest } from "../../../../repository/UserManagement/organization_pb";
import { countriesAR, countriesEn } from "../../../../app/Content";


let req: UpdateAccountSecurityRequest;


let promise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;

export const AccountSecurityForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const state: { isLoading: boolean, message: ApiMessage | undefined, organization?: OrganizationState, stage1: any, stage2: any, stage3: any } = useAppSelector((state) => {

        return {
            isLoading: state.managersUsersForm.isLoading, message: state.managersUsersForm.message, organization: state.managersUsersForm.currentOrganization
            , stage1: state.managersUsersForm.stage1
            , stage2: state.managersUsersForm.stage2
            , stage3: state.managersUsersForm.stage3

        }
    })


    useEffect(() => {

        req = new UpdateAccountSecurityRequest();

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        } else {

            if (props.type == FormType.EDIT) {
                if (props.fetchData) {
                    let r = new GetUserRequest()
                    r.setId(props?.renderObject?.internalId);
                    promise?.abort()
                    promise = dispatch(getManager({ body: r, headers: getHeaders() }))

                } else {
                    dispatch(setStage1(props?.renderObject))
                    dispatch(setStage2(props?.renderObject))
                    dispatch(setStage3(props?.renderObject))
                }
            }
        }


        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])


    return (
        <>

            <Formik
                enableReinitialize={true}
                initialValues={{

                    isEmailTFEnabled: state.stage2?.isEmailTFEnabled ?? false,
                    //  isAuthAppTFEnabled: state.stage2.isAuthAppTFEnabled,
                    //  isPhoneTFEnabled: state.stage2.isPhoneTFEnabled,
                    TFEmail: state.stage2?.TFEmail ? state.stage2?.TFEmail : state.stage2?.primaryEmail ? state.stage2?.primaryEmail : "",
                    //  TFPhone: state.stage2.TFPhone,
                }}

                validationSchema={Yup.object({

                    TFEmail: inputs.TwoFactorEmail,
                    //  primaryPhoneNumber: inputs.primaryPhoneNumber,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.EDIT) {
                        req.setProfileid(props.renderObject?.internalId)
                        if (values.TFEmail.trim().length == 0) {
                            req.setIsemailtfenabled(false)
                        } else {
                            req.setIsemailtfenabled(values.isEmailTFEnabled)
                        }
                        const r = new StringValue();
                        r.setValue(values.TFEmail.trim());
                        req.setTfemail(r)

                        promise = dispatch(updateAccountSecurity({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateAccountSecurityResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            internalId: props.renderObject?.internalId,
                                            TFEmail: values.TFEmail,
                                            isEmailTFEnabled: values.isEmailTFEnabled,

                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })


                    }
                }}

            >
                {formkikProps => (
                    <Form autoComplete="off">

                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            {props.type == FormType.EDIT ? <Toggle

                                onText={t("enabled")} offText={t("disabled")}
                                label={
                                    <div>
                                        {t("emailTwoFactor") + ' '}
                                        <TooltipHost content={t("emailTwoFactorDesc")}>
                                            <Icon iconName="Info" aria-label={t("emailTwoFactor")} />
                                        </TooltipHost>
                                    </div>
                                }
                                disabled={state.isLoading}
                                checked={formkikProps.values.isEmailTFEnabled}

                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isEmailTFEnabled', checked);
                                }
                                }
                            />
                                : null}

                            {props.type == FormType.EDIT && formkikProps.values.isEmailTFEnabled == true ? <Field name="TFEmail" label={t("email")}
                                placeholder={"abc@gmail.com"} dir="ltr" component={InputField} disabled={state.isLoading} type="email" required
                            />
                                : null}

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("save")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>


                    </Form>
                )
                }
            </Formik >
        </>
    );
}




